import React, { useEffect } from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { down } from 'styled-breakpoints';
import { StaticImage } from 'gatsby-plugin-image'
//DATA
import { proj, www, app, cms } from '../assets/data/oferta'
//STYLES
import { ContainerMob, Section, CardWrapper, Logo } from '../utils/utils'
//COMPONENTS
import SEO from '../components/seo/SEO'
import Layout from '../layout/layoutBW'
import OfertaInfo from '../components/oferta/OfertaInfo'
import CardSimple from '../components/portfolioCard/CardSimple'

const Wrapper = styled(ContainerMob)`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 4rem;
    overflow: hidden;
    align-items: center;

    ${down("sm")} {
        grid-template-columns: 1fr;
    }
`;

const Wrapper2 = styled(Wrapper)`
    grid-template-columns: 1fr 2fr;
    ${down("sm")} {
        grid-template-columns: 1fr;
    }
`;

const Page2 = ({
    data: {
        allMdx: {
            nodes
        },
    },
}) => {

    useEffect(() => {
        const hashtag = window.location.hash
        if (hashtag) {
            const currentURL = window.location.href
            const newURL = currentURL.split('#')[0]
            window.history.replaceState('', 'Lunch', newURL)
        }
    })

    return <div>
        <SEO
            title="Home"
            slug='/page2' />
        <Layout>
            <Logo />
            <Section
                id={proj.id}
                pt='7rem'>
                <Wrapper>
                    <OfertaInfo
                        heading={proj.heading}
                        title={proj.title}
                        intro={proj.intro}
                        lista={proj.lista}
                        link={proj.link}
                    />
                    <div data-sal='slide-left'
                        data-sal-duration='600'>
                        <StaticImage
                            alt={proj.title}
                            src='../assets/images/oferta_projektowanie.png'
                        />
                    </div>
                </Wrapper>
            </Section>

            <Section
                id={www.id}
                bgColor={props => props.theme.color.grey9}>
                <Wrapper2>
                    <div data-sal='slide-right'
                        data-sal-duration='600'>
                        <StaticImage
                            alt={www.title}
                            src='../assets/images/oferta_www.png'
                        />
                    </div>
                    <OfertaInfo
                        sal='left'
                        heading={www.heading}
                        title={www.title}
                        intro={www.intro}
                        lista={www.lista}
                        link={www.link}
                    />
                </Wrapper2>
            </Section>

            <Section
                id={cms.id}>
                <Wrapper>
                    <OfertaInfo
                        heading={cms.heading}
                        title={cms.title}
                        intro={cms.intro}
                        lista={cms.lista}
                        link={cms.link}
                    />
                    <div data-sal='slide-left'
                        data-sal-duration='600'>
                        <StaticImage
                            alt={cms.title}
                            src='../assets/images/oferta_cmss.png'
                        />
                    </div>
                </Wrapper>
            </Section>

            <Section
                id={app.id}
                //ref={myTargetRef}
                bgColor={props => props.theme.color.grey9}>
                <Wrapper2>
                    <div data-sal='slide-right'
                        data-sal-duration='600'>
                        <StaticImage
                            alt={app.title}
                            src='../assets/images/oferta_app.png'
                        />
                    </div>
                    <OfertaInfo
                        sal='left'
                        heading={app.heading}
                        title={app.title}
                        intro={app.intro}
                        lista={app.lista}
                        link={app.link}
                    />
                </Wrapper2>
            </Section>
            <CardWrapper
                colGap='2rem'
                rowGap='2rem' mb='0'
                xl='3' l='3' m='3'
            >
                {nodes.map((node) => {
                    return (
                        <CardSimple
                            key={nodes.indexOf(node)}
                            name={node.frontmatter.title}
                            shortList={node.frontmatter.shortList}
                            link={node.frontmatter.slug}
                            image={node.frontmatter.mainImage.file.childImageSharp.gatsbyImageData}
                            alt={node.frontmatter.mainImage.alt}
                        />
                    )
                })}
            </CardWrapper>
        </Layout>
    </div>
}
export default Page2

export const query = graphql`
query {
    allMdx (
        filter: {frontmatter: {idPage: {eq: "portfolio"}}}
        sort: {order: DESC, fields: frontmatter___id}
        limit: 3
        ){
        nodes {
            body
            frontmatter {
                title
                intro
                slug
                shortList
                mainImage{
                    alt
                    file {
                        childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              aspectRatio: 1
                              placeholder: BLURRED
                              )
                          }
                    }
                }
                
            }
        }
    }
}
`