import React from 'react'
import styled from 'styled-components'
//STYLED
import { H6, H2, Text, LabelBW } from '../../utils/typo'
import { theme } from "../../utils/theme";
//COMPONENTS
import Button from '../button/Button'

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;

    p {
        white-space: pre-wrap;
    }
`;

class OfertaInfo extends React.Component {

    render() {
        return (
            <InfoWrapper
                data-sal={`slide-${this.props.sal || 'right'}`}
                data-sal-duration='600'>
                <LabelBW
                    weight='300'
                    textTransform='uppercase'
                    colorFont={theme.color.grey5}
                >
                    {this.props.heading.map((i) => <>{i}<b> • </b></>)}
                </LabelBW>
                <H2 mt='2rem'
                    mb='1rem'>
                    {this.props.title}
                </H2>
                <Text
                    weight='500'
                    mb='2rem'>
                    — {this.props.intro}
                </Text>

                <ul>
                    {this.props.lista.map((li) => (
                        <li key={li}>
                            {li}
                        </li>))}
                </ul>
                {this.props.link == '/' ?
                    <></>
                    :
                    <Button link={this.props.link}>
                        Więcej
                </Button>}

            </InfoWrapper>
        )
    }
}
export default OfertaInfo