export const proj = {
    id: 'proj',
    heading: ['materiały reklamowe', 'DTP', 'UI design'],
    title: 'Projektowanie graficzne',
    intro: 'Projektowanie graficzne to nasza pasja, staramy się aby nasze projekty były user friendly oraz wpisywały się w najnowsze trendy.',
    lista: [
        'materiały reklamowe do publikacji w internecie,',
        'materiały do druku (plakaty, ulotki, foldery, banery...),',
        'strony internetowe (UI design),',
        'aplikacje webowe (UI, UX design),',
        'aplikacje desktopowe (UI, UX design).'
    ],
    link: '/'
}

export const www = {
    id: 'www',
    heading: ['strony www', 'strony wizytówki', 'witryny firmowe'],
    title: 'Strony internetowe',
    intro: 'Tworzymy strony internetowe w oparciu o najnowsze technologie i trendy w projektowaniu. Strony są semantyczne, przyjazne użytkownikowi, responsywne oraz zoptymalizowane pod kątem SEO. \nWykonujemy:',
    lista: [
        'strony typu one-page,',
        'landing page,',
        'strony ofertowe,',
        'strony firmowe i korporacyjne,',
        'strony produktowe',
        'strony eventowe,',
        'strony w oparciu o nasz projekt graficzny, jak i projekt dostarczony przez klienta.'
    ],
    link: '/'
}

export const cms = {
    id: 'cms',
    heading: ['CMS', 'zarządzanie tręścią', 'CRM'],
    title: 'Strony z systemem zarządzania treścią CMS',
    intro: 'Dzięki systemowi zarządzania treścią nasz klient może samodzielnie uzupełniać treści na stronie, robić wpisy na blogu, edytować informacje, a to wszystko w prosdym i intuicyjnym systemie CMS.',
    lista: [
        'edycja informacji znajdujących sie na stronie',
        'możliwość dodawania podstron, artykułów, informacji, zdjęć itp.',
        'tworzenie bloga,',
        'intuicyjny system zarządzania treścią.'
    ],
    link: '/'
}

export const app = {
    id: 'app',
    heading: ['Aplikacje', 'serwisy', 'CRM'],
    title: 'Aplikacje i serwisy webowe',
    intro: 'Projektowanie UI oraz UX design aplikacji, a następnie wdrażanie ich w nowoczesnych technologiach webowych lub desktopowych w zależności od potrzeb klienta.',
    lista: [
        'UI i UX design,',
        'aplikacje webowe,',
        'aplikacje desktopowe.',
    ],
    link: '/'
}