import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { down, up } from 'styled-breakpoints';
import { LabelBW } from '../../utils/typo';

const Card = styled(Link)`
    position: relative;
    background-color: ${props => props.bgcolor || props.theme.color.baseExtraLight};
    transition: all ease-in-out .7s;

    > div {
        height: 100%;
        width: 100%;
    }
`;

const Content = styled.div`
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 2rem;
    transition: all ease-in-out .7s;

    ${down('xs')} {
    display: none;
    }
    
    :hover{
        opacity: 1;
        //background-color: ${props => props.bgcolor || props.theme.color.grey4};

        ::after {
            content: '';
            opacity: .9;
            position: absolute;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${props => props.bgcolor || props.theme.color.white};
            border: 1px solid ${props => props.theme.color.grey7}
        }
    }

    p, h3, li {
        position: relative;
        line-height: 1.2;
        transition: all ease-in-out .7s;
        z-index: 100;
    }

    p, li {
        margin-top: 1rem;
    }

    li {
        list-style-type: none;
        margin-left: 2rem;
        font-weight: 500;
        position: relative;

        ::before {
            content: '—> ';
            display: block;
            position: absolute;
            left: -2rem;
        }
    }
`;

const TagWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: end;

    ${up('sm')} {
    display: none;
    }

    div {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }
`;

const Tag = styled.p`
    background-color: ${props => props.theme.color.grey1};
    color: white;
    display: block;
    align-self: flex-start;
    padding: .3rem .8rem;
    margin-top: .5rem;
    margin-left: .5rem;
    border-radius: 10px;
    font-weight: 500;
`;

class CardSimple extends React.Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (
            <Card
                bgcolor={this.props.bgcolor}
                colorfont={this.props.colorfont}
                data-sal="fade"
                data-sal-delay='100'
                data-sal-duration='600'
                to={this.props.link || '/'}
            >
                <GatsbyImage
                    alt={this.props.alt}
                    image={this.props.image} />

                <Content>
                    <LabelBW mb='1rem'>Portfolio</LabelBW>
                    <h3>
                        {this.props.name}
                    </h3>
                    <ul>
                        {this.props.shortList == null ? (<></>) : (
                            this.props.shortList.map((item) => (
                                <li key={this.props.shortList.indexOf(item) + 1}>{item}</li>
                            ))
                        )}
                    </ul>
                </Content>
                <TagWrapper>
                    <div>
                        {this.props.shortList == null ? (<></>) : (
                            this.props.shortList.map((item) => (
                                <Tag key={this.props.shortList.indexOf(item) + 1}>{item}</Tag>
                            ))
                        )}
                    </div>

                </TagWrapper>
            </Card>
        )
    }
}
export default CardSimple